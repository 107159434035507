import axios from "@/axios.js";
import store from "@/store/index.js";
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'auth/SET_TOKEN':
            if (mutation.payload !== null) {
                axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`;
                localStorage.setItem('token', mutation.payload);
            } else {
                axios.defaults.headers.common.Authorization = null;
                localStorage.removeItem('token');
            }
        break;
        default:
            break;
    }
})
