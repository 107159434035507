// axios
import axios from 'axios';
import router from './router';
// const domain = 'http://52.62.192.54/api/v1/';
const domain = 'https://jobcards.tyregeneral.co.nz/api/v1';
const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
};
const axiosObj = axios.create({
    baseURL: domain,
    timeout: 100000,
    headers,
});

axiosObj.interceptors.response.use(
    response => response,
    error => {
        // Do something with response error
        if (error.response.status === 403) {
            console.log('unauthorized, logging out ...');
            router.replace('/login');
        }
        return Promise.reject(error.response);
    },
);

export default axiosObj;
