import axios from "@/axios.js";
import router from "@/router/index.js";
export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        impersonate: false,
    },
    actions: {
        async login({ dispatch }, credentials) {
            try {
                const response = await axios.post('login', credentials)
                if (response.data.status === false) {
                    return response.data;
                }
                if (response.status === 401) {
                    throw new Error("Invalid Credentials");
                    // return response.data;
                }

                dispatch('attempt', response.data.access_token);
                router.push(router.currentRoute.query.redirect || '/account/dashboard')
                return true
            } catch (err) {
                throw new Error("Invalid Credentials");
            }
        },

        async startImpersonate({ dispatch, state }, credentials) {
            const response = await axios.post('impersonate', credentials)
            if (response.data.status === false) {
                return response.data;
            }

                localStorage.setItem('impersonate_token', state.token);
                dispatch('attempt', response.data.access_token)
                state.impersonate = true;
                // router.push(router.currentRoute.query.to || '/account/dashboard')
                return true
        },

        async stopimpersonate({ dispatch, state }) {
            return axios.get('auth/logout').then(() => {
                const token = localStorage.getItem('impersonate_token');
                dispatch('attempt', token);
                state.impersonate = false;
                // router.push(router.currentRoute.query.to || '/account/dashboard')
            });
        },

        async logout({ commit }) {
            return axios.get('/logout').then(() => {
                commit('SET_TOKEN', null)
                commit('UPDATE_USER', null)
            });
        },

        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return;
            }
            try {
                const response = await axios.get('profile').catch(() => {
                    token.notify({
                        title: 'Login Attempt',
                        text: 'Invalid Credentials',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning',
                    })
                });
                commit('UPDATE_USER', response.data)
            } catch (e) {
                commit('UPDATE_USER', null)
                commit('SET_TOKEN', null)
            }
        },

    },
    mutations: {
        SET_TOKEN(state, token) {
            if (token || token === null) { state.token = token }
        },

        UPDATE_USER(state, data) {
            state.user = data
            if (data !== null) {
                state.user.photoURL = data.avatar
                state.user.status = "online"
                state.user.userRole = "admin"
            }
        },
    },
    getters: {
        isAuthenticated(state) {
            return (state.token && state.user)
        },
        getAuthenticatedUser(state) {
            return state.user
        },
        getAuthenticatedToken(state) {
            return state.token
        },
    },
}
