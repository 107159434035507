import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import VuexPersist from 'vuex-persist'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from "./auth"

const vuexLocalStorage = new VuexPersist({
  key: 'dunedin_casino',
  storage: window.localStorage,
})
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
  },
  plugins: [vuexLocalStorage.plugin],
  strict: process.env.DEV,
})
