import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import moment from 'moment'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueLazyLoad from 'vue-lazyload'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import router from './router'
import store from './store'
import App from './App.vue'

Vue.use(VueLazyLoad);

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import helper from "./helper.js"
Vue.prototype.$helper = helper

// axios
import axios from "./axios.js"
Vue.prototype.$axios = axios

Vue.prototype.$moment = moment

Vue.use(VueLodash, { name: 'custom', lodash })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(CKEditor)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/store/subscriber')
store.dispatch('auth/attempt', localStorage.getItem('token'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
