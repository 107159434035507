/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
import store from './store';
export default {
	showLoading: () => {
		const appLoading = document.getElementById('loading-bg');
		if (appLoading) {
			appLoading.style.display = "block";
		}
	},
	hideLoading: () => {
		const appLoading = document.getElementById('loading-bg');
		if (appLoading) {
			appLoading.style.display = "none";
		}
	},
	checkUserPermission: (action, slug, permissions) => {
		const blockAction = ['import', 'export', 'delete', 'create'];
		const blockSlug = ['customer', 'roles', 'users', 'grouped orgs', 'customers', 'classes & wheel layouts', 'vehicles', 'settings', 'notifications'];
		const roleId = store.state.auth.user.roles[0].id
		if (slug === 'dashboard') { return true }
		if (slug === 'clients' && blockAction.indexOf(action) >= 0) { return false }
		if (roleId === 6 && (blockSlug.indexOf(slug) >= 0 || blockAction.indexOf(action) >= 0)) { return false }
		const requiredPermission = `${slug}-${action}`;
		return permissions.indexOf(requiredPermission) >= 0;
	},
	ucFirst(str) {
		return str ? str[0].toUpperCase() + str.slice(1) : '';
	},
	removeByAttr(arr, attr, value) {
		let i = arr.length;
		while (i--) {
			if (arr[i]
				&& arr[i].hasOwnProperty(attr)
				&& (arguments.length > 2 && arr[i][attr] === value)) {
				arr.splice(i, 1);
			}
		}
		return arr;
	},
};
