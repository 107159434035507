import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import('@/views/authentication/Login.vue'),
            meta: {
                guest: true,
                layout: 'full',
            },
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('@/views/authentication/ForgotPassword.vue'),
            meta: {
                guest: true,
                layout: 'full',
            },
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@/views/authentication/ResetPassword.vue'),
            meta: {
                guest: true,
                layout: 'full',
            },
        },
        {
            path: '/job-report/:id',
            name: 'job-report',
            component: () => import('@/views/authentication/jobcard.vue'),
            meta: {
                guest: false,
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/account/dashboard',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Home',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/profile',
            name: 'profile',
            component: () => import('@/views/profile/AccountSetting.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Profile',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Profile',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/companies/create',
            name: 'Grouped-Orgs-create',
            component: () => import('@/views/companies/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/users',
            name: 'users',
            component: () => import('@/views/users/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Users',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Users',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/clients',
            name: 'Customers',
            component: () => import('@/views/clients/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Customers',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Customers',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/classes',
            name: 'Classes & Wheel Layouts',
            component: () => import('@/views/classesWheelLayouts/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Classes & Wheel Layouts',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Classes & Wheel Layouts',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/companies',
            name: 'Grouped-Orgs',
            component: () => import('@/views/companies/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Grouped Orgs',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Grouped Orgs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/vehicles',
            name: 'vehicles',
            component: () => import('@/views/vehicles/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Vehicles',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Vehicles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/notifications',
            name: 'notifications',
            component: () => import('@/views/notifications/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Notification Hub',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Notification Hub',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/jobs',
            name: 'jobs',
            component: () => import('@/views/jobs/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Jobs',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Jobs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/removalreasons',
            name: 'removalreasons',
            component: () => import('@/views/removal_reasons/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Removal Reasons',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Removal Reasons',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/approval-times',
            name: 'approval-times',
            component: () => import('@/views/approval_times/index.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Approval Times',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Approval Times',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/:modules',
            name: 'module-list',
            component: () => import('@/views/common/list.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'List',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/account/jobs/show/:id',
            name: 'jobs-edit',
            component: () => import('@/views/jobs/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/vehicles/show/:id',
            name: 'vehicles-edit',
            component: () => import('@/views/vehicles/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/notificationtemplates/view/:id',
            name: 'notificationtemplates-edit',
            component: () => import('@/views/notification_templates/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/notificationtemplates/create',
            name: 'notificationtemplates-create',
            component: () => import('@/views/notification_templates/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/vehicles/create',
            name: 'vehicles-create',
            component: () => import('@/views/vehicles/components/addvehicle.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/wheellayouts/create',
            name: 'Wheel-Layouts-create',
            component: () => import('@/views/classesWheelLayouts/components/createwheel.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/services/create',
            name: 'Services-create',
            component: () => import('@/views/services/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/jobs/create',
            name: 'jobs-create',
            component: () => import('@/views/jobs/create.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/jobs/complete',
            name: 'jobs-complete',
            component: () => import('@/views/jobs/complete.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Complete Jobs',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Complete Jobs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/users/create',
            name: 'users-create',
            component: () => import('@/views/users/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/:modules/create',
            name: 'module-create',
            component: () => import('@/views/common/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Create',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Create',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/companies/view/:id',
            name: 'Grouped-Orgs-edit',
            component: () => import('@/views/companies/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/wheellayouts/view/:id',
            name: 'Wheel-Layouts-edit',
            component: () => import('@/views/classesWheelLayouts/components/viewEditWheel.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/services/view/:id',
            name: 'Services-edit',
            component: () => import('@/views/services/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/users/view/:id',
            name: 'users-edit',
            component: () => import('@/views/users/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/account/:modules/view/:id',
            name: 'module-edit',
            component: () => import('@/views/common/createorupdate.vue'),
            meta: {
                layout: 'collapsed',
                pageTitle: 'Update',
                requiresAuth: true,
                breadcrumb: [
                    {
                        text: 'Update',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let pagetitle = '';
        if (to.params.modules) {
            pagetitle = to.params.modules.toString();
        } else {
            pagetitle = to.name;
        }
        pagetitle = pagetitle.replaceAll('-', ' ');
        // eslint-disable-next-line no-param-reassign
        to.meta.pageTitle = pagetitle.charAt(0).toUpperCase() + pagetitle.slice(1);
        if (localStorage.getItem('token') == null) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath },
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (localStorage.getItem('token') == null) {
            next();
        } else {
            next({ path: 'account/dashboard' });
        }
    } else {
        next();
    }
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
